import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FiExternalLink } from 'react-icons/fi'
import Seo from '../components/Seo/Seo'

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
  h1 {
    width: 100%;
    background: ${(props) => props.theme.global.bg};
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 0.5em 0;
    color: ${(props) => props.theme.global.color};
  }

  a {
    background: ${(props) => props.theme.global.bg};
    width: 100%;
    color: ${(props) => props.theme.global.color};
  }

  ul {
    padding: 0;
  }

  li:before {
    content: '▹';
    color: ${(props) => props.theme.global.color};
    margin-right: 5px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    h1 {
      width: 600px;
    }

    a {
      width: 600px;
    }
  }
`

const Content = styled.div`
  margin-top: 2rem;
  max-width: 600px;
  line-height: 1.8rem;

  h2:nth-child(n + 2),
  h3 {
    margin-top: 3rem;
  }
`

const ProjectLink = styled.a`
  padding: 1rem 0;
  color: rgb(${(props) => props.theme.global.link});
  margin: 0em;
  text-transform: uppercase;
  gap: 10px;
`

const ProjectDetails = ({ data }) => {
  const { html } = data.markdownRemark
  const { title, link } = data.markdownRemark.frontmatter
  const image =
    data.markdownRemark.frontmatter.img.childImageSharp.gatsbyImageData
  return (
    <Section>
      <Seo title={title}/>
      <h1>{title}</h1>
      <GatsbyImage image={image} alt='hey' />
      <ProjectLink href={link} target='_blank'>
        Live demo <FiExternalLink />
      </ProjectLink>
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Section>
  )
}

export default ProjectDetails

export const query = graphql`
  query ProjectsPage($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        link
        img {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
    }
  }
`
